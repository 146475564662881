<template>
  <div class="vowel-consonant">
    <div class="tabs d-flex justify-content-center align-items-center">
      <div
        :class="['tabs-item', activeTabs ? '' : 'active']"
        @click="changeTabs(0)"
      >
        21个元音
        <span></span>
      </div>
      <div class="border po-r"></div>
      <div
        :class="['tabs-item', activeTabs ? 'active' : '']"
        @click="changeTabs(1)"
      >
        19个辅音
        <span></span>
      </div>
    </div>
    <div v-show="!activeTabs" class="tabs-list">
      <div class="item bg-fff" v-for="(rose, idx) in vowelList">
        <div class="label">
          {{ rose.title }} ({{ rose.syllableDetail.length }})个
        </div>
        <div class="list d-grid list-four" @click="changeMask">
          <span
            v-for="(lisa, index) in rose.syllableDetail"
            class="d-flex align-items-center justify-content-center"
            >{{ lisa.syllables }}</span
          >
        </div>
      </div>
    </div>
    <div v-show="activeTabs" class="tabs-list">
      <div class="item bg-fff" v-for="(rose, idx) in cononantList">
        <div class="label">
          {{ rose.title }} ({{ rose.syllableDetail.length }})个
        </div>
        <div class="list d-grid list-five" @click="changeMask">
          <span
            v-for="(lisa, index) in rose.syllableDetail"
            class="d-flex align-items-center justify-content-center"
            >{{ lisa.syllables }}</span
          >
        </div>
      </div>
    </div>

    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱日语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSyllableList } from '@/api/fortyTone'
export default {
  name: '',
  data() {
    return {
      vowelList: [],
      cononantList: [],
      activeTabs: 0,
      maskShow: false,
    }
  },

  created() {
    this.getSyllableList()
  },

  methods: {
    async getSyllableList() {
      const { data, code } = await getSyllableList()
      if (code != 'SUCCESS') return
      let vowe = []
      let duble = []

      data.map((item) => {
        if (item.title == '单元音') {
          vowe.push(item.syllableDetail)
        } else if (item.title == '双元音') {
          duble.push(item.syllableDetail)
        } else {
          item.title = item.title.replace('辅音-', '')
          this.cononantList.push(item)
        }
      })
      this.vowelList[0] = {
        title: '单元音',
        syllableDetail: [...vowe.flat()],
      }
      this.vowelList[1] = {
        title: '双元音',
        syllableDetail: [...duble.flat()],
      }
    },
    changeTabs(type) {
      this.activeTabs = type
    },
    changeMask() {
      this.maskShow = !this.maskShow
    },
    download() {},
  },
}
</script>

<style lang="scss" scoped>
.vowel-consonant {
  padding: 0 16px;
  background: #f5f6f9;
  min-height: 100vh;
  overflow: hidden;
  .tabs {
    color: #333;
    letter-spacing: 0.8px;
    font-size: 18px;
    // line-height: 28px;
    font-weight: 500;
    padding: 30px 0;
    font-weight: 700;

    &-item {
      // padding: 0 34px;
      position: relative;
      z-index: 2;
      span {
        position: absolute;
        bottom: -1px;
        z-index: -1;
        display: block;
        // width: 77px;
        width: 100%;
        height: 5px;
        border-radius: 3px;
        /* 蓝变紫渐变 */
        background: #f5f6f9;
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
      &.active {
        span {
          /* 蓝变紫渐变 */
          background: linear-gradient(270deg, #4e6aff 0%, #88c9ff 100%);
        }
      }
    }
    .border {
      width: 1px;
      height: 17px;
      margin: 0 34px;
      background: #e2e2e2;
      z-index: 2;
    }
  }
  .tabs-list {
    .item {
      padding: 12px 16px 18px 16px;
      border-radius: 12px;
      box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.5);
      margin-bottom: 16px;
      .label {
        color: #333;
        letter-spacing: 0.3px;
        line-height: 41px;
        font-size: 18px;
        margin-bottom: 12px;
        font-weight: 700;
      }
      .list {
        &.list-four {
          grid-template-columns: repeat(4, 1fr);
          // grid-template-columns: repeat(4, 25%);
          grid-gap: 28px 37px;
        }
        &.list-five {
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 15px;
        }
        span {
          width: 50px;
          height: 50px;
          border-radius: 6px;
          background: #f5f6fa;
          // line-height: 50px;
          color: #4e6aff;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
